<template>
  <div v-if="is_Login">
    <div v-if="isGuide" class="main-body">
      <div class="tab-list step1">
        <div v-if="contract_list_guide.length>0" class="tab-contract_list">
          <div v-for="item in contract_list_guide" :class="select_contract_guide.contractId===item.contractId?'tab-item tab-item-active':'tab-item'" @click="handleSelectTab(item)">
            <div class="tab-item-text">{{item.contractName}}</div>
          </div>
        </div>
        <div class="tab-list-empty" v-else>
          <img src="../../static/emtry.png" alt="">
          <div class="emtry-info">暂无数据</div>
        </div>
        <el-button type="primary" class="add-btn" @click="" icon="el-icon-plus">新增合同</el-button>
        <el-button  @click="" type="primary" plain class="close-btn">
          <img src="../../static/return-close.svg" alt="">
          <div>
            返回流程申请页面
          </div>
        </el-button>
      </div>
      <div class="edit-list" v-if="select_contract_guide">
        <div class="left-qys">
          <div class="moduleForm-box step2">
            <div class="form-title">
              <div class="left-border"></div>
              <div class="form-text-info">合同信息</div>
            </div>
            <el-form class="moduleForm">
              <el-form-item  class="first-item">
                <div class="form-label">公司主体<span>（必填）</span></div>
                <el-select
                  v-model="searchDataGuide.company"
                  clearable
                  filterable
                  placeholder="选择公司主体">
                  <el-option
                    v-for="item in companyListGuide"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="first-item">
                <div class="form-label">合同模板<span>（必填）</span></div>
                <el-select
                  v-model="searchDataGuide.template"
                  clearable
                  filterable
                  placeholder="选择合同模板">
                  <el-option
                    v-for="item in templateListGuide"
                    :key="item.id"
                    :label="item.name"
                    :disabled="item.disabled"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <div class="form-label">最晚签署日期<span>（必填）</span></div>
                <el-date-picker
                  v-model="searchDataGuide.date"
                  value-format="yyyy-MM-dd"
                  type="date"
                  :picker-options="signDateUpData"
                  placeholder="选择最晚签署日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <div class="form-label">合同名称<span>（必填）</span></div>
                <el-input clearable v-model="searchDataGuide.contractName" placeholder="请输入合同名称"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="tool step3">
            <div class="form-title">
              <div class="left-border"></div>
              <div class="form-text-info">大写金额转换工具</div>
            </div>
            <div class="tool-flex">
              <div class="tool-left">
                <el-input placeholder="请输入数字金额" maxlength="15" clearable  @input="handleInputGuide" v-model="moneyGuide" class="input-money"></el-input>
              </div>
              <div class="tool-right" id="tool">
                <el-input placeholder="大写金额显示位置" disabled v-model="money_big_guide" class="input-with-select">
                </el-input>
                <el-button slot="append" type="primary"  @click="copyValue(money_big_guide)" :disabled="disableBig">
                  <img src="../../static/copy.svg" alt="">
                  <div>
                    复制
                  </div>
                </el-button>
              </div>
            </div>
          </div>
          <div class="left-content step4">
            <div class="form-title">
              <div class="left-border"></div>
              <div class="form-text-info">参数</div>
            </div>
            <div v-if="dataParametersListGudie.length>0"  class="contact-content-param">
              <el-form class="contact-form">
                <el-form-item v-for="item in dataParametersListGudie" :key="item.name" :class="item.type==='htmlText'?'public-textarea-item':'public-item'">
                  <div class="label">{{ item.name }}<span v-if="item.required">（必填）</span></div>
                  <div class="form-content radio-padding" v-if="item.type==='radio'||item.type==='checkbox'">
                    <div v-if="item.type==='radio'">
                      <el-radio
                        v-for="(i,index) in item.options"
                        :key="index"
                        v-model="item.value"
                        :label="i.label"
                        :value="i.label"
                        border>
                        {{ i.label }}
                      </el-radio>
                    </div>
                    <div v-if="item.type==='checkbox'">
                      <el-checkbox
                        v-for="(i,index) in item.options"
                        :key="i.id"
                        v-model="item.isCheck.find(o=>o.id===i.id).check"
                        :label="i.label"
                        :value="i.id"
                        border>
                        {{ i.label }}
                      </el-checkbox>
                    </div>
                  </div>
                  <div class="form-content" v-else>
                    <el-input
                      v-if="item.type==='htmlText'"
                      v-model="item.value" type="textarea"
                      :placeholder="'输入'+ item.name" clearable>
                    </el-input>
                    <el-input
                      v-if="item.type==='text'"
                      v-model="item.value"
                      :placeholder="'输入'+ item.name"
                      clearable>
                    </el-input>
                    <el-input
                      v-if="item.type==='person'"
                      maxlength="18"
                      v-model="item.value"
                      :placeholder="'输入'+ item.name"
                      clearable>
                    </el-input>
                    <el-select
                      v-if="item.type==='select'"
                      v-model="item.value"
                      clearable
                      filterable
                      :placeholder="'选择'+item.name">
                      <el-option
                        v-for="item in item.options"
                        :key="item.id"
                        :label="item.label"
                        :value="item.label">
                      </el-option>
                    </el-select>
                    <el-cascader
                      v-if="item.type==='select_multiple'"
                      v-model="item.value"
                      clearable
                      filterable
                      :placeholder="'选择'+item.name+'（可多选）'"
                      :options="item.options"
                      collapse-tags
                      :props="{value: 'id', label: 'label',multiple: true}">
                    </el-cascader>
                  </div>
                </el-form-item>
              </el-form>
            </div>
            <div v-else class="contract-emtry" style="height: calc(100% - 52px)">
              <div class="emtry-center">
                <img src="../../static/emtry.png" alt="">
                <div class="emtry-info">暂无数据</div>
              </div>
            </div>
          </div>
          <div class="footer-fix step5" v-if="select_contract_guide.isSigned_already===0">
            <el-button  type="danger" plain @click="">
              <img src="../../static/delete-btn.svg" alt="">
              <div>删除当前合同</div>
            </el-button>
            <el-button type="primary" :loading="saveLoad" @click="">
              <img src="../../static/save-preview.svg" alt="" v-if="!saveLoad">
              <div>保存数据并预览合同</div>
            </el-button>
          </div>

        </div>
        <div class="right-qys step6">
          <div class="form-title">
            <div class="left-border"></div>
            <div class="form-text-info">合同预览</div>
          </div>
          <div class="contact-content" style="height: calc(100% - 52px);position: relative;overflow: hidden">
            <div
              v-if="pdfLoadGudie"
              v-loading="pdfLoadGudie"
              class="loading"
              element-loading-spinner="el-icon-loading"
              element-loading-text="加载中,请稍等">
            </div>
            <div v-else style="height: 100%;width: 100%">
              <div v-if="pdfUrlGudie" style="height: 100%;width: 100%">
                <PdfViewer :pdfUrl="pdfUrlpreGudie" :accessToken="accessTokenGudie" />
              </div>
              <div v-else class="pdfEmtry">
                <div class="emtry-center">
                  <img src="../../static/emtry.png" alt="">
                  <div class="emtry-info">暂无数据</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="contract-empty" v-else>
        <img src="../../static/emtry.png" alt="">
        <div class="emtry-info">暂无数据</div>
      </div>
      <!-- 引导提示区域   -->
      <!-- 遮罩层 -->
      <div  class="guide-mask"></div>
      <!-- 指导框 -->
      <div  class="guide-box" :style="currentStepPosition">
<!--         <div class="guide-skip" @click="finishGuide">跳过</div>-->
        <div class="guide-title">{{currentStep.title}}</div>
        <!-- 指导内容 -->
        <div class="guide-content" v-if="currentStep.isArr">
          <div v-for="item in currentStep.content" class="guide-item">
            <div class="mark"></div>
            <div>{{item}}</div>
          </div>
        </div>
        <div v-else class="guide-item">
          {{currentStep.content}}
        </div>
        <div class="guide-footer">
          <div class="block">{{ currentStepIndex+1 }}/{{steps.length}}</div>
          <!-- 按钮 -->
          <div class="guide-buttons">
            <el-button v-if="currentStepIndex > 0" @click="prevStep" type="primary" plain>上一步</el-button>
            <el-button v-if="currentStepIndex < steps.length - 1" @click="nextStep" type="primary">下一步</el-button>
            <el-button v-else @click="finishGuide" type="primary">我知道了</el-button>
          </div>
        </div>
        <div class="arrow" :style="currentArrowPosition"></div>
      </div>
      <!-- 引导提示区域   -->
    </div>
    <div class="main-body" v-else v-loading="list_loading">
      <div class="tab-list">
        <div v-if="contract_list.length>0" class="tab-contract_list">
          <div v-for="item in contract_list" :class="select_contract.contractId===item.contractId?'tab-item tab-item-active':'tab-item'" @click="handleSelectTab(item)">
            <div class="tab-item-text">{{item.contractName}}</div>
          </div>
        </div>
        <div class="tab-list-empty" v-else>
          <img src="../../static/emtry.png" alt="">
          <div class="emtry-info">暂无数据</div>
        </div>
        <el-button type="primary" class="add-btn" @click="handleAddContract" icon="el-icon-plus">新增合同</el-button>
        <el-button  @click="close" type="primary" plain class="close-btn">
          <img src="../../static/return-close.svg" alt="">
          <div>
            返回流程申请页面
          </div>
        </el-button>
      </div>
      <div class="edit-list" v-if="select_contract">
        <div class="left-qys">
          <div class="scroll-qys-left">
            <div class="moduleForm-box">
              <div class="form-title">
                <div class="left-border"></div>
                <div class="form-text-info">合同信息</div>
              </div>
              <el-form class="moduleForm">
                <el-form-item  class="first-item">
                  <div class="form-label">公司主体<span>（必填）</span></div>
                  <el-select
                    v-model="searchData.company"
                    clearable
                    filterable
                    placeholder="选择公司主体"
                    @change="handeChangeCompany">
                    <el-option
                      v-for="item in companyList"
                      :key="item.name"
                      :label="item.name"
                      :value="item.name">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item  class="first-item">
                  <div class="form-label">签约项目<span>（必填）</span></div>
                  <el-select
                    v-model="searchData.project"
                    clearable
                    filterable
                    placeholder="选择签约项目"
                    @change="handeChangeProject">
                    <el-option
                      v-for="item in projectList"
                      :key="item.id"
                      :label="item.flmc"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item  class="first-item">
                  <div class="form-label">报价类型<span>（必填）</span></div>
                  <el-select
                    v-model="searchData.pieceType"
                    clearable
                    filterable
                    placeholder="选择报价类型"
                    @change="handeChangePieceType">
                    <el-option
                      v-for="item in pieceTypeList"
                      :key="item.id"
                      :label="item.flmc"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="first-item">
                  <div class="form-label">合同模板<span>（必填）</span></div>
                  <el-select
                    v-model="searchData.template"
                    clearable
                    filterable
                    placeholder="选择合同模板"
                    @change='handeChangeTemplate'>
                    <el-option
                      v-for="item in templateList"
                      :key="item.id"
                      :label="item.name"
                      :disabled="item.disabled"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item  class="first-item" v-if="isShowSpecialType">
                  <div class="form-label">是否双申<span>（必填）</span></div>
                  <el-select
                    v-model="searchData.isApply"
                    clearable
                    filterable
                    placeholder="选择是否双申"
                    @change="handleChangeApply">
                    <el-option
                      v-for="item in isApplyList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item  class="first-item" v-if="isShowSpecialType">
                  <div class="form-label">特批类型 <div class="re-multiple">（多选）</div></div>
                  <el-cascader
                    v-model="searchData.specialType"
                    clearable
                    filterable
                    :placeholder="'选择特批类型（可多选）'"
                    @change="handeChangeSpecialType"
                    :options="isSpecialTypeList"
                    collapse-tags
                    :props="{value: 'id', label: 'label',multiple: true,emitPath:false}">
                  </el-cascader>
                </el-form-item>
                <el-form-item>
                  <div class="form-label">最晚签署日期<span>（必填）</span></div>
                  <el-date-picker
                    v-model="searchData.date"
                    @change="handleChangeInput"
                    value-format="yyyy-MM-dd"
                    type="date"
                    :picker-options="signDateUpData"
                    placeholder="选择最晚签署日期">
                  </el-date-picker>
                </el-form-item>
                <el-form-item>
                  <div class="form-label">合同名称<span>（必填）</span>
                    <span class="question-icon">
                    <el-tooltip  effect="light" placement="top" content="提示内容">
                      <img src="../../static/questions.svg">
                      <template slot="content">
                        <div>
                          此合同名将展示在发送给客户的消息中
                        </div>
                      </template>
                    </el-tooltip>
                    </span>
                  </div>
                  <el-input clearable v-model="searchData.contractName" @change="handleChangeInput" placeholder="" disabled></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div class="remain-special" v-if="isShowSpecialType">
              <div class="form-title">
                <div class="left-border"></div>
                <div class="form-text-info">剩余特批名额</div>
              </div>
              <div class="special-box">
                <div v-for="item in isSpecialTypeList" class="special-item">
                  <div class="special-title">{{item.label}}</div>
                  <div class="special-times">{{item.remainingQuota}}</div>
                </div>
              </div>
            </div>
            <div class="tool">
              <div class="form-title">
                <div class="left-border"></div>
                <div class="form-text-info">大写金额转换工具</div>
              </div>
              <div class="tool-flex">
                <div class="tool-left">
                  <el-input placeholder="请输入数字金额" maxlength="15" clearable  @input="handleInput" v-model="money" class="input-money"></el-input>
                </div>
                <div class="tool-right" id="tool">
                  <el-input placeholder="大写金额显示位置" disabled v-model="money_big" class="input-with-select">
                  </el-input>
                  <el-button slot="append" type="primary"  @click="copyValue(money_big)" :disabled="disableBig">
                    <img src="../../static/copy.svg" alt="">
                    <div>
                      复制
                    </div>
                  </el-button>
                </div>
              </div>
            </div>
            <div class="left-content">
              <div class="form-title">
                <div class="left-border"></div>
                <div class="form-text-info">参数</div>
              </div>
              <div v-if="pramaLoading" v-loading="pramaLoading" class="param-box">

              </div>
              <div v-else>
                <div v-if="dataParametersList.length>0" class="contact-content-param">
                  <el-form class="contact-form">
                    <el-form-item v-for="item in dataParametersList" :key="item.name" :class="item.type==='htmlText'?'public-textarea-item':'public-item'">
                      <div class="label">{{ item.name }}<span v-if="item.required">（必填）</span></div>
                      <div class="form-content radio-padding" v-if="item.type==='radio'||item.type==='checkbox'">
                        <div v-if="item.type==='radio'">
                          <el-radio
                            v-for="(i,index) in item.options"
                            :key="index"
                            @change="handleChangeInput"
                            v-model="item.value"
                            :label="i.label"
                            :value="i.label"
                            border>
                            {{ i.label }}
                          </el-radio>
                        </div>
                        <div v-if="item.type==='checkbox'">
                          <el-checkbox
                            v-for="(i,index) in item.options"
                            @change="handleChangeInput"
                            :key="i.id"
                            v-model="item.isCheck.find(o=>o.id===i.id).check"
                            :label="i.label"
                            :value="i.id"
                            border>
                            {{ i.label }}
                          </el-checkbox>
                        </div>
                      </div>
                      <div class="form-content" v-else>
                        <el-input
                          v-if="item.type==='text'"
                          @change="handleChangeInput"
                          v-model="item.value"
                          :placeholder="'输入'+ item.name"
                          clearable>
                        </el-input>
                        <el-input
                          v-if="item.type==='person'"
                          @change="handleChangeInput"
                          maxlength="18"
                          v-model="item.value"
                          :placeholder="'输入'+ item.name"
                          clearable>
                        </el-input>
                        <el-select
                          v-if="item.type==='select'"
                          v-model="item.value"
                          clearable
                          filterable
                          :placeholder="'选择'+item.name"
                          @change="handleChangeInput">
                          <el-option
                            v-for="item in item.options"
                            :key="item.id"
                            :label="item.label"
                            :value="item.label">
                          </el-option>
                        </el-select>
                        <el-cascader
                          v-if="item.type==='select_multiple'"
                          v-model="item.value"
                          clearable
                          filterable
                          :placeholder="'选择'+item.name+'（可多选）'"
                          :options="item.options"
                          collapse-tags
                          :props="{value: 'label', label: 'label',multiple: true}"
                          @change="handleChangeCascader(item)">
                        </el-cascader>
                        <el-cascader
                          v-if="item.type==='htmlText'&&item.name==='服务费收费标准'"
                          v-model="selectOrderItem"
                          clearable
                          filterable
                          :placeholder="'选择'+item.name+'（可多选）'"
                          :options="orderList"
                          collapse-tags
                          :props="{value: 'id', label: 'mc',multiple: true,emitPath:false}"
                          @change="handleChangeOrder(item)">
                        </el-cascader>
                        <el-input
                          class="person-info"
                          v-else-if="item.type==='htmlText'&&item.name==='副申请人信息'"
                          type="textarea"
                          v-model="personTextArea"
                          :placeholder="'输入'+ item.name"
                          @change="handleChangeInput"
                          show-word-limit
                        ></el-input>
                        <el-input
                          v-else-if="item.type==='htmlText'&&item.name==='补充或变更条款'"
                          type="textarea"
                          v-model="selectedSpecialItems"
                          :placeholder="'输入'+ item.name"
                          show-word-limit
                          disabled
                        ></el-input>
                        <el-input
                          v-else-if="item.type==='htmlText'&&item.name==='联系地址'"
                          type="textarea"
                          v-model="contractInfo.address"
                          :placeholder="'输入'+ item.name"
                          show-word-limit
                          disabled
                        ></el-input>
                        <el-input
                          v-else-if="item.type==='htmlText'&&item.name==='联系方式'"
                          type="textarea"
                          v-model="contractInfo.contactInformation"
                          :placeholder="'输入'+ item.name"
                          show-word-limit
                          disabled
                        ></el-input>
                        <el-input
                          v-else-if="item.type==='htmlText'&&item.name==='账户名'"
                          type="textarea"
                          v-model="contractInfo.accountName"
                          :placeholder="'输入'+ item.name"
                          show-word-limit
                          disabled
                        ></el-input>
                        <el-input
                          v-else-if="item.type==='htmlText'&&item.name==='银行账号'"
                          type="textarea"
                          v-model="contractInfo.bankAccount"
                          :placeholder="'输入'+ item.name"
                          show-word-limit
                          disabled
                        ></el-input>
                        <el-input
                          v-else-if="item.type==='htmlText'&&item.name==='开户行'"
                          type="textarea"
                          v-model="contractInfo.openingBank"
                          :placeholder="'输入'+ item.name"
                          show-word-limit
                          disabled
                        ></el-input>
                        <div class="wang-editor"  style="border: 1px solid #ccc;" v-else-if="isShowWang&&item.type==='htmlText'">
                          <Toolbar
                            :editor="editor"
                            :defaultConfig="toolbarConfig"
                            :mode="mode"/>
                          <Editor
                            style="height: 390px; overflow-y: hidden;"
                            v-model="item.value"
                            :defaultConfig="editorConfig"
                            :mode="mode"
                            @onCreated="onCreated"/>
                        </div>
                        <el-input
                          v-else-if="item.type==='htmlText'"
                          @change="handleChangeInput"
                          v-model="item.value" type="textarea"
                          :placeholder="'输入'+ item.name">
                        </el-input>
                        <el-date-picker
                          v-if="item.type==='date'"
                          v-model="item.value"
                          value-format="yyyy-MM-dd"
                          type="date"
                          @change="handleChangeInput"
                          :placeholder='"选择"+item.name'>
                        </el-date-picker>
                      </div>
                    </el-form-item>
                  </el-form>
                </div>
                <div v-else class="contract-emtry" style="height: calc(100% - 52px)">
                  <div class="emtry-center">
                    <img src="../../static/emtry.png" alt="">
                    <div class="emtry-info">暂无数据</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer-fix" v-if="select_contract.isSigned_already===0">
            <el-button  type="danger" plain @click="delContract">
              <img src="../../static/delete-btn.svg" alt="">
              <div>删除当前合同</div>
            </el-button>
            <el-button type="primary" :loading="saveLoad" @click="preview">
              <img src="../../static/save-preview.svg" alt="" v-if="!saveLoad">
              <div>保存数据并预览合同</div>
            </el-button>
          </div>
        </div>
        <div class="right-qys">
          <div class="form-title">
            <div class="left-border"></div>
            <div class="form-text-info">合同预览</div>
          </div>
          <div class="contact-content" style="height: calc(100% - 52px);position: relative;overflow-y: hidden">
            <div
              v-if="pdfLoad"
              v-loading="pdfLoad"
              class="loading"
              element-loading-spinner="el-icon-loading"
              element-loading-text="加载中,请稍等">
            </div>
            <div v-else style="height: 100%;width: 100%">
              <div v-if="pdfUrl" style="height: 100%;width: 100%">
                <PdfViewer :pdfUrl="pdfUrlpre" :accessToken="accessToken" />
              </div>
              <div v-else class="pdfEmtry">
                <div class="emtry-center">
                  <img src="../../static/emtry.png" alt="">
                  <div class="emtry-info">暂无数据</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="contract-empty" v-else>
        <img src="../../static/emtry.png" alt="">
        <div class="emtry-info">暂无数据</div>
      </div>
    </div>
    <!--  弹窗提示  -->
    <el-dialog
      :title="dialogTitle"
      :show-close="false"
      class="warn-dialog"
      :close-on-click-modal="true"
      append-to-body
      :visible.sync="warnDialogVisable"
      @close="handelCloseDialog"
      width="384px"
      height="215px">
      <div class="dialog-img">
        <img :src="dialogIcon" />
      </div>
      <span class="result-info">
        {{dialogText}}
      </span>
      <div slot="footer" class="dialog-footer">
        <el-button @click="warnDialogVisable=false">取 消</el-button>
        <el-button type="primary" @click="handlePreserve" :loading="warnDialogLoading" style="margin-left: 16px">确 定</el-button>
      </div>
    </el-dialog>
    <!--  弹窗提示  -->
  </div>

</template>

<script>
// @ is an alias to /src
import {
  CheckPerson,
  CreateStandardContract,
  GetCategoryTagList,
  GetCompanyentity,
  GetContractInfo,
  GetContractPreviewData,
  GetConttemplate,
  GetNewContractData,
  GetServiceFeeList,
  GetSpecialTagList,
  GetStandard,
  GetTemplateParametersList,
  RemoveContract
} from "@/api";
import {isInterceptorCode} from "@/common/commonFuncs";
import PdfViewer from '../unit/pdfViewer.vue';
import '@wangeditor/editor/dist/css/style.css'
import {Editor, Toolbar} from "@wangeditor/editor-for-vue";
export default {
  name:'contractStandard',
  components: {PdfViewer,Editor, Toolbar},
  props:['routerParams'],
  data() {
    return {
      /*合同列表*/
      contract_list:[],//合同列表全部的
      un_save_contract:[],//未保存的合同列表
      select_contract:'',//默认选中的合同
      list_loading:false,//合同loading
      isShowApply:false,//是否显示是否双申
      isShowSpecialType:false,//是否显示特批类型
      searchData: {//下拉列表数据选择回显
        company: '',
        project:'',
        pieceType:'',
        isApply:'1',
        specialType:'',
        template: '',
        date: '',
        contractName:'',
      },
      isApplyList:[
        {id:'0',name:'是'},
        {id:'1',name:'否'},
      ],
      isSpecialTypeList:[],
      selectedSpecialItems:'',//选中的
      disableBig:false,//大写禁用
      signDateUpData: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      radio: '',
      checked1: '',
      checked2: '',
      numPages: 1,//pdf页数
      pdfUrl: '',
      pdfLoad: false,//加载pdf状态
      showFirst: false,//是否显示第一个光标
      companyList: [],//公司主体
      projectList:[],
      pieceTypeList:[],
      templateList: [],//合同模板
      idType:[
        {name:'身份证'}
      ],
      requestid: '',
      contractId:'',
      pramaLoading:false,
      dataParametersList: [],//右侧参数列表
      orderList:[],//,服务收费标准下拉
      orderText:'',//服务收费转换后
      selectOrderItem:[],
      selectOrderList:'',//服务收费标准已选回显
      personTextArea:'',//服务标准内容
      contractInfo:{
        address:'',
        contactInformation:'',
        accountName:'',
        bankAccount:'',
        openingBank:'',
      },//合同写死参数带人数据
      codeBlur: false,//身份证号input聚焦状态
      previewLoad: false,//预览loading状态
      saveLoad: false,//保存loading状态
      money: '', // 数字金额
      money_big: '', // 金额大写
      /* 文件预览 */
      pdfUrlpre:'',
      accessToken:'',

      isFormDirty: false, // 标记表单是否被修改
      is_operate:true,//判断如果签署中和签署完成不可编辑和删除
      is_Login:false,//是否可以登陆

      /*提示弹出框*/
      warnDialogVisable:false,
      warnDialogLoading:false,
      dialogTitle:"",
      dialogText:"",
      dialogIcon:'',

      /*引导区域数据*/
      isGuide:false,//引导是否显示
      contract_list_guide:[
        {
          contractId: 101,
          contractName: "契约锁合同",
          isSigned_already: 1,
          is_created: true,
        },
        {
          contractId: 102,
          contractName: "这里是写不完只能隐藏",
          isSigned_already: 0,
          is_created: true,
        },
        {
          contractId: 103,
          contractName: "契约锁合同",
          isSigned_already: 1,
          is_created: true,
        },
        {
          contractId: 101,
          contractName: "契约锁合同",
          isSigned_already: 1,
          is_created: true,
        },
      ],//合同列表
      select_contract_guide:{
        contractId: 102,
        contractName: "这里是写不完只能隐藏",
        isSigned_already: 0,
        is_created: true,
      },//选择的合同
      searchDataGuide: {
        company: '示例公司',
        template: '1001',
        date: new Date(),
        contractName:'test',
      },//下拉列表数据选择回显
      companyListGuide:[
        {name:"示例公司"}
      ],
      templateListGuide:[
        {
        "id": "1001",
        "name": "test",
        "tags": null
        }
      ],
      moneyGuide:'',
      money_big_guide:'',
      dataParametersListGudie:[
        {
          "name": "姓名",
          "required": false,
          "type": "text",
          "options": [],
          "isCheck": [],
          "value": "诸葛亮"
        },
        {
          "name": "证件号码",
          "required": false,
          "type": "person",
          "options": [],
          "isCheck": [],
          "value": "210103199608213311"
        },
        {
          "name": "性别",
          "required": false,
          "type": "person",
          "options": [],
          "isCheck": [],
          "value": "女"
        }
      ],//参数数组
      pdfUrlGudie: '',
      pdfLoadGudie:false,
      pdfUrlpreGudie:'',
      accessTokenGudie:'',
      steps: [
        { id: 1,
          title:'合同管理',
          isArr:true,
          content:[
            '新增合同：用于添加合同，如果同一客户有多个合同需要签署，点击新增合同逐一添加和编辑。',
            '返回流程申请页面：退出当前合同起草页面，返回到ERP流程申请页面。切记，返回并不保存数据。建议点击保存数据并预览合同后再返回。',
            '合同列表：点击任意合同，切换合同再查看和编辑。'
          ],
          className: 'step1'
        },
        { id: 2,
          title:'合同信息',
          isArr:true,
          content: [
            '公司主体：选择客户签约的公司主体，签哪个公司就选哪个公司即可。',
            '合同模板：选择客户签约的合同模板，客户符合那个类型的合同就选哪个合同模板即可。',
            '最晚签署日期：选择客户签约合同的最晚签署日期，要求客户在此时间前完成合同签署。',
            '合同名称：给当前签署合同起一个名字，名字会同步显示到左侧目录和流程申请页面。'
          ],
          className: 'step2' },
        { id: 3,
          title: '大写金额转换工具',
          isArr:false,
          content: '填入小写金额，系统将自动转换成大写的金额，并提供一键复制，避免金额数据填写错误。',
          className: 'step3' },
        { id: 4,
          title: '参数',
          content: '合同模板中需要填写的信息，按照页面正确填写信息即可。',
          isArr:false,
          className: 'step4' },
        { id: 5,
          isArr:true,
          title: '操作按钮',
          content: [
            '删除当前合同：将当前编辑的合同删除，已保存的信息也将完全删除，请妥善使用删除功能。',
            '保存数据并预览合同：将当前合同中编辑的信息保存，并同步显示在右侧预览合同中。'
          ],
          className: 'step5' },
        { id: 6,
          title: '合同预览',
          isArr:false,
          content: '检查数据在合同中是否正确。确认无误后，可以添加下一份合同或返回流程申请页面。',
          className: 'step6' },
      ],
      currentStepIndex: 0, // 当前步骤索引
      /*富文本部分*/
      /*富文本部分*/
      editor: null,
      toolbarConfig: {
        excludeKeys: ["emotion", "group-video","group-image","fullScreen"],
        // toolbarKeys: [
        //   'headerSelect',
        //   'blockquote',
        //   '|',
        //   "bold",            // 加粗
        //   "underline",       // 下划线
        //   "italic",          // 斜体
        //   'color',           // 字体颜色
        //   'bgColor',         // 背景颜色
        //   '|',
        //   'fontSize',        //默认字号
        //   'fontFamily',      //默认字体
        //   'lineHeight',      //行高
        //   '|',
        //   'bulletedList',    //无序列表
        //   'numberedList',    //有序列表
        //   'todo',            //代办
        //
        //   // 'group-justify',   //对齐方式
        //   // "group-indent",    // 缩进
        //
        //   '|',
        //   "insertLink",      // 插入链接
        //   "insertTable",     // 插入表格
        //   "codeBlock",       // 插入代码块
        //   "divider",         // 分割线
        //   '|',
        //   "undo",            // 撤销
        //   "redo",            // 重做
        //   '|',
        //   "fullScreen",      // 全屏
        //
        //   // "clearStyle",      // 清除格式
        //
        // ],
      },
      editorConfig: {
        placeholder: '输入内容...',
        MENU_CONF: {
        },
      },
      mode: 'default', // or 'simple'
    }
  },
  computed: {
    // 当前步骤
    currentStep() {
      return this.steps[this.currentStepIndex];
    },
    // 指导框的位置
    currentStepPosition() {
      const targetElement = document.querySelector(`.${this.currentStep.className}`);
      if (targetElement) {
        const rect = targetElement.getBoundingClientRect();
        switch (this.currentStep.className) {
          case'step1': return{
            position: 'absolute',
            top: `80px`,
            left: `231px`,
            maxWidth:`755px`
          };
          case'step2': return{
            position: 'absolute',
            top: `${targetElement.offsetTop+20}px`,
            left: `${rect.left + window.scrollX+targetElement.offsetWidth+39}px`,
            maxWidth:`700px`
          };
          case'step3': return{
            position: 'absolute',
            top: `${targetElement.offsetTop+20}px`,
            left: `${rect.left + window.scrollX+targetElement.offsetWidth+39}px`,
            maxWidth:`500px`
          };
          case'step4': return{
            position: 'absolute',
            top: `${targetElement.offsetTop+20}px`,
            left: `${rect.left + window.scrollX+targetElement.offsetWidth+39}px`,
            maxWidth:`610px`
          };
          case'step5': return{
            position: 'absolute',
            bottom: `141px`,
            top:'unset',
            left: `${rect.left + window.scrollX+targetElement.offsetWidth-610}px`,
            maxWidth:`610px`
          };
          case'step6': return{
            position: 'absolute',
            top: `185px`,
            left: `${rect.left + window.scrollX-642}px`,
            maxWidth: `610px`
          };
        }
      }
      return {};
    },
    // 指导框箭头
    currentArrowPosition(){
      const targetElement = document.querySelector(`.${this.currentStep.className}`);
      if (targetElement) {
       if(this.currentStep.className==='step5'){
         return{
           right:'67px',
           bottom:'-10px',
           left:'unset',
           top:'unset'
         };
       }else if(this.currentStep.className==='step6'){
         return{
           right:'-10px',
           top:'30px',
           left:'unset',
           bottom:'unset'
         };
       }
      }
    },
    // 检查是否所有下拉框都有值
    allSelected() {
      return this.searchData.company&&this.searchData.project&&this.searchData.pieceType
    },
    // 定义特殊值
    updates() {
      const { address, contactInformation, accountName, bankAccount, openingBank } = this.contractInfo;
      return [
        { name: '服务费收费标准', value: this.orderText },
        { name: '副申请人信息', value: this.personTextArea },
        { name: '补充或变更条款', value: this.selectedSpecialItems },
        { name: '联系地址', value: address },
        { name: '联系方式', value: contactInformation },
        { name: '账户名', value: accountName },
        { name: '银行账号', value: bankAccount },
        { name: '开户行', value: openingBank },
      ];
    },
    isShowWang(){
      return this.searchData.pieceType&&this.pieceTypeList.find(i=>i.id===this.searchData.pieceType).flmc==='特批条款合同'
    },
  },
  watch: {
    // 检查特批类型
    'searchData.specialType': function (newVal, oldVal){
      if(newVal){
        const selectedItems = newVal.map(value => {
          // 显式返回 find 的结果
          return this.isSpecialTypeList.find(item => item.id === value)?.describe;
        });
        this.selectedSpecialItems=selectedItems.join('\n')
      }
    },
    'searchData.isApply':function (newVal, oldVal){
      if(!newVal){
        this.searchData.isApply='1'
      }else{
        this.getSpecialTagData()
      }
    },
    'searchData.company':function (newVal,oldVal){
      // 将主体特定配置信息带入
      let findInfo=this.companyList.find(i=>i.name===this.searchData.company)
      if(this.searchData.company){
        this.contractInfo = {
          address: findInfo.address || '',
          contactInformation: findInfo.contactInformation || '',
          accountName: findInfo.accountName || '',
          bankAccount: findInfo.bankAccount || '',
          openingBank: findInfo.openingBank || '',
        };
      }else{
        this.contractInfo = {
          address:  '',
          contactInformation: '',
          accountName:  '',
          bankAccount: '',
          openingBank: '',
        };
      }
    }
  },
  methods: {
    // 登陆
    loginCheckToken(){
      let para={
        requestId:this.requestid,
        // contractId:this.contractId,
      }
      CheckPerson(para).then(res => {
        if (res.code === 200) {
          this.is_Login=true
          if(res.data.isHave===1){
            this.pdfUrlGudie='https://ydyd-crm.oss-cn-beijing.aliyuncs.com/qys/preView/3307269840347115671/业务合同测试模板.pdf'
            this.getPdfPreGudie()
            this.isGuide=true
            this.$nextTick(() => { // 等待 Vue 渲染完成
              const currentElement = document.querySelector('.step1');
              if (currentElement) {
                currentElement.classList.add('highlight');
              }
            });
          }else{
            this.getCompany()//获取公司主体下拉
            this.getCategoryTagData()//获取签约项目+报价类型下拉
            this.getSpecialTagData()// 获取特批类型下拉
            if (this.requestid) {
              this.getContractInfoList('create')
            }
          }
        } else {
          this.$router.push({
            name: 'errorPage',
            query:{
              message:res.msg
            }
          })
        }
      }).catch(() => {
        this.$message.error('服务器连接失败!')
      })
    },
    // 关闭监听是否有内容
    handleBeforeUnload(event) {
      if (this.isFormDirty) {
        const message = "你有未保存的更改，确定要离开吗？";
        // 对大部分浏览器返回一个标准的提示框
        event.returnValue = message;
        return message; // 为了兼容性
      }
    },
    // 清除全部内容
    handleCleanData(){
      this.templateList=[]
      this.searchData.date=""
      this.searchData.company=""
      this.searchData.project=""
      this.searchData.pieceType=""
      this.searchData.isApply='1'
      // this.isSpecialTypeList=[]
      this.searchData.specialType=""
      this.searchData.template=""
      this.searchData.contractName=""
      this.money=""
      this.money_big=""
      this.dataParametersList=[]
      this.pdfLoad=false
      this.pdfUrl=""
      this.pdfUrlpre=""
      this.accessToken=""
      this.isShowApply=false
      this.isShowSpecialType=false
      this.selectedSpecialItems=''
      this.selectOrderItem=[]
      this.orderText=''
    },
    // 清除合同全部内容
    handleCleanContact(){
      this.templateList=[]
      this.searchData.isApply='1'
      this.searchData.specialType=''
      this.searchData.template=''
      this.dataParametersList=[]
      this.pdfUrl=''
      this.pdfUrlpre=''
      this.accessToken=''
      this.pdfLoad=false
      this.selectedSpecialItems=''//补充或变更条款传回后端值
      this.selectOrderItem=[]//服务费收费标准下拉值
      this.orderText=''//服务费收费标准传回后端值
    },
    // 预览右侧数据
    preview() {
      this.updates.forEach(({ name, value }) => this.updateDataParameters(name, value));
      let para = {
        requestid: this.requestid,//流程id
        contractId:this.select_contract.is_created?this.select_contract.contractId:"",//已创建的合同
        companyName: this.searchData.company,//公司主体
        signedProject:this.searchData.project,//签约项目
        quotationType:this.searchData.pieceType,//报价类型
        isDouble:this.searchData.isApply,//是否双申
        specialTags:this.searchData.specialType.length>0?this.searchData.specialType.join(','):'',//特批类型
        caregoryId: this.searchData.template,//合同模板
        expireTime: this.searchData.date,//最晚签约时间
        contractName:this.searchData.contractName,//合同名称
        multipleId:this.selectOrderItem.join(','),//服务费收费标准多选id
        templateParams: this.dataParametersList//参数
      }
      para.templateParams.map(item=>{
        if(item.type==='select_multiple'){
          if(typeof item.value !=='string'){
            item.value=item.value.join(',')
          }
        }
      })
      if (!para.requestid) {
        this.$message.warning('流程ID不能为空')
        return
      }
      if (!para.companyName) {
        this.$message.warning('请选择公司主体')
        return
      }
      if (!para.signedProject) {
        this.$message.warning('请选择签约项目')
        return
      }
      if (!para.quotationType) {
        this.$message.warning('请选择报价类型')
        return
      }
      // let selectOption= this.projectList.find(i=>i.id===para.signedProject)
      // if(selectOption.sfss===0){
      //   if (para.isDouble==='') {
      //     this.$message.warning('请选择是否双申')
      //     return
      //   }
      // }
      if(this.isSpecialTypeList){
        if (para.isDouble==='') {
          this.$message.warning('请选择是否双申')
          return
        }
      }
      if (!para.quotationType) {
        this.$message.warning('请选择报价类型')
        return
      }
      if (!para.caregoryId) {
        this.$message.warning('请选择合同模板')
        return
      }
      if (!para.expireTime) {
        this.$message.warning('最晚签署日期不能为空')
        return
      }
      if(!para.contractName){
        this.$message.warning('合同名称不能为空')
        return
      }
      let required = []
      let unRequired = []
      let regex = 11 && /^1[3456789]\d{9}$/
      let check_email = /^([a-zA-Z\d])(\w|\-)+@[a-zA-Z\d]+\.[a-zA-Z]{2,4}$/; // 邮箱正则判断
      required = para.templateParams.filter(i => i.required === true)
      unRequired = para.templateParams.filter(i => i.required === false)
      for (let i = 0; i < required.length; i++) {
        const item = required[i]


        if (item.name === '手机' && item.value !== '') {
          if (!regex.test(item.value)) {
            this.$message.error('手机号格式不正确!')
            return
          }
        }
        if (item.name === '邮箱' && item.value !== '') {
          if (!check_email.test(item.value)) {
            this.$message.error('邮箱格式不正确!')
            return
          }
        }
        if (item.type === 'person') {
          if (item.value === '') {
            this.$message.warning('请输入' + item.name + '!')
            return
          }
        }
        if (item.type === 'person' && item.value) {
          if (!this.validateIDCard(item.value)) {
            this.$message.error('身份证号码格式不正确！')
            return
          }
        }
        if (item.type === 'passport') {
          if (item.value === '') {
            this.$message.warning('请输入' + item.name + '!')
            return
          }
        }
        if (item.type === 'passport' && item.value) {
          if (!this.valPassport(item.value)) {
            this.$message.error('护照号码格式不正确，请确保护照号码为有效的字母和数字组合！')
            return
          }
        }
        if (item.type === 'checkbox') {
          // 判断 多选选项状态中是否都为false
          let checkStatus = item.isCheck.every(obj => obj.check === false);
          if (checkStatus) {
            this.$message.warning('请选择' + item.name + '!')
            return
          }
        }
        if (item.type === 'select'||item.type === 'select_multiple'||item.type==='date'||item.type === 'radio'||item.type==='htmlText'&&item.name==='服务费收费标准') {
          if (item.value === '') {
            this.$message.warning('请选择' + item.name + '!')
            return
          }
        }
        else if (item.type === 'htmlText'||item.type === 'text') {
          if (item.value === '') {
            this.$message.warning('请输入' + item.name + '!')
            return
          }
        }
      }
      for (let x = 0; x < unRequired.length; x++) {
        const unItem = unRequired[x]
        if (unItem.name === '手机' && unItem.value !== '') {
          if (!regex.test(unItem.value)) {
            this.$message.error('手机号格式不正确!')
            return
          }
        }
        if (unItem.name === '邮箱' && unItem.value !== '') {
          if (!check_email.test(unItem.value)) {
            this.$message.error('邮箱格式不正确!')
            return
          }
        }
        if (unItem.type === 'person' && unItem.value) {
          if (!this.validateIDCard(unItem.value)) {
            this.$message.error('身份证号码格式不正确！')
            return
          }
        }
        if (unItem.type === 'passport' && unItem.value) {
          if (!this.valPassport(unItem.value)) {
            this.$message.error('护照号码格式不正确，请确保护照号码为有效的字母和数字组合！')
            return
          }
        }
      }
      this.saveLoad = true
      CreateStandardContract(para).then(res => {
        if (res.code === 200) {
          this.$message.success('保存成功')
          this.saveLoad = false
          if(this.un_save_contract.length>0){
            if(this.select_contract.contractId===this.un_save_contract[0].contractId){
              this.un_save_contract=[]
            }
          }
          this.getContractInfoList()
          this.isFormDirty=false
        } else {
          if (!isInterceptorCode(res.code)) {
            this.$message.error(res.msg)
            this.saveLoad = false
          }
        }
      }).catch((e) => {
        this.$message.error('服务器连接失败!')
        console.log(e)
        this.saveLoad = false
      })
    },
    // 获取特殊参数赋值
    updateDataParameters(name, value) {
      let index = this.dataParametersList.findIndex(i => i.name === name);
      if (index !== -1) {
        this.dataParametersList[index].value = value;
      }
    },
    // 关闭当前会话
    close() {
      this.warnDialogVisable=true
      this.dialogTitle = '返回流程申请页面'
      this.dialogIcon= require('../../static/blue-icon.svg')
      this.dialogText='即将返回流程申请页面，您当前未保存的数据将会丢失，您确定返回吗？'
    },
    codeInputBlur(code) {
      this.codeBlur = false
      if (code.length === 0) {
        this.showFirst = false
      }
    },
    codeInputFocus(code) {
      this.codeBlur = true
      if (code.length === 0) {
        this.showFirst = true
      }
    },
    codeInputChange(code) {
      if (code.length > 0) {
        this.showFirst = false
      } else {
        this.showFirst = true
      }
    },
    // 参数-单行输入框
    handleChangeInput(){
      this.isFormDirty=true
    },
    // 参数-富文本
    handleChangeCascader(item){
      this.isFormDirty=true
      item.value=item.value.join(',');
    },
    // 参数-服务标准
    handleChangeOrder(item){
      this.isFormDirty=true
      if(this.selectOrderItem.length>0){
        const selectedItems =this.selectOrderItem.map(value =>
          this.orderList.find(item => item.id === value).ms
        );
        this.orderText=selectedItems.join('\n')
      }else{
        this.orderText=''
      }
    },
    // 新增合同
    handleAddContract(){
      let matchingContracts=[]
      if(this.contract_list.length>0){
        matchingContracts = this.contract_list.filter(contract => contract.is_created === false);
      }
      if(matchingContracts.length===1){
        alert("你有未保存的合同,暂时不能添加新的合同")
        return
      }else{
        this.handleCleanData()
        // 如果是新合同就默认显示历史选择的公司主体签约项目
        this.getNewContractDataInfo()
        this.select_contract=""
        this.templateList=[]

        // 找到当前列表中最大的 contractId，然后加 1
        const newContract = {
          contractId: 1,
          contractName: "新合同",
          isSigned_already: 0,
          is_created: false,
        };
        // 添加新合同
        this.un_save_contract.push(newContract);
        this.contract_list = this.contract_list.concat(this.un_save_contract)
        this.select_contract=newContract
      }
    },
    // 选择合同
    handleSelectTab(item){
      if(this.isFormDirty){
        if (window.confirm('你有未保存的更改，确定要离开吗？')) {
          // 用户点击了“确定”
          this.isFormDirty=false
          if(!this.pdfLoad){
            this.handleCleanData()
            this.getContractList(item)
            document.querySelector('.scroll-qys-left').scrollTop=0
          }

        }else{
          console.log("用户不确定")
        }
      }else{
         if(!this.pdfLoad){
        this.handleCleanData()
        this.getContractList(item)
        document.querySelector('.scroll-qys-left').scrollTop=0
        }
      }
      // 如果选择的是新合同就默认显示历史选择的公司主体签约项目
      if(!item.is_created){
        this.getNewContractDataInfo()
      }
    },
    // 删除合同
    delContract(){
      this.dialogTitle='删除当前合同'
      this.dialogIcon=require('../../static/red-icon.svg')
      this.dialogText='您确定将当前合同删除吗？'
      this.warnDialogVisable=true
    },
    // 改变公司主体
    handeChangeCompany(val) {
      this.isFormDirty=true
      // 只要修改公司主体就清空合同
      this.handleCleanContact()

      // 判断公司主体签约项目报价类型是否都有值
      if(this.allSelected){
        this.getContractTemplate()
      }
    },
    // 改变签约项目
    handeChangeProject(val){
      this.isFormDirty=true
      // 只要修改签约项目就清空合同
      this.handleCleanContact()

      // 判断公司主体签约项目报价类型是否都有值
      if(this.allSelected){
        this.getContractTemplate()
      }

      // 签约项目是美国项目的时显示是否双申
      let selectIndex = this.projectList.findIndex(i=>i.id===val)
      if(selectIndex!==-1){
        let selectOption= this.projectList.find(i=>i.id===val)
        if(selectOption.sfss===0){
          this.isShowApply=true
        }else{
          this.isShowApply=false
        }
      }else{
        this.isShowApply=false
      }
      this.searchData.isApply='1'
    },
    // 改变报价类型
    handeChangePieceType(val){
      this.isFormDirty=true
      // 只要修改报价类型就清空合同
      this.handleCleanContact()

      // 判断公司主体签约项目报价类型是否都有值
      if(this.allSelected){
        this.getContractTemplate()
      }
      // if(this.searchData)
      if(val){
        // 当报价类型选择的是时显示特批类型
        this.getSpecialTagData()
      }
    },
    // 改变特批类型
    handeChangeSpecialType(values){
      this.isFormDirty=true
      if(values.length>0){
        const selectedItems = values.map(value =>
          this.isSpecialTypeList.find(item => item.id === value).describe
        );
        this.selectedSpecialItems=selectedItems.join('\n')
      }
    },
    // 改变合同模板
    handeChangeTemplate(val) {
      this.isFormDirty=true
      this.handelSetSpecial()
      if(val){
        this.getParameter()
        this.getstandardPdf()
        this.searchData.contractName=this.templateList.find(i=>i.id===val).externalName
      }else{
        this.dataParametersList=[]
        this.pdfUrl=""
        this.pdfUrlpre=''
        this.accessToken=''
        this.pdfLoad=false
      }
    },
    // 改变是否双申
    handleChangeApply(){
      this.isFormDirty=true
      // this.searchData.specialType=''
      // this.selectedSpecialItems=''

    },
    // 文件预览new
    getPdfPre(){
      let data={
        path:this.pdfUrl
      }
      this.$axios.post('https://file-preview.wmenzai.com/translate.php',data,{
        headers: {
          "Content-Type":"application/json"
        },
      }).then(res =>{
        // 请求成功状态为200说明添加成功
        if(res.data.code===200){
          this.pdfUrlpre=res.data.data.body.webofficeURL
          this.accessToken=res.data.data.body.accessToken
          this.pdfLoad = false
        }else {
          if (!isInterceptorCode(res.code)) {
            this.$message.error(res.message)
          }
          this.pdfLoad = false
        }
      }).catch(error =>{
        this.$message.error('服务器连接失败!')
        this.pdfLoad = false
      })
    },
    // 文件预览new
    getPdfPreGudie(){
      let data={
        path:this.pdfUrlGudie
      }
      this.pdfLoadGudie=true
      this.$axios.post('https://file-preview.wmenzai.com/translate.php',data,{
        headers: {
          "Content-Type":"application/json"
        },
      }).then(res =>{
        // 请求成功状态为200说明添加成功
        if(res.data.code===200){
          this.pdfUrlpreGudie=res.data.data.body.webofficeURL
          this.accessTokenGudie=res.data.data.body.accessToken
          this.pdfLoadGudie = false
        }else {
          if (!isInterceptorCode(res.code)) {
            this.$message.error(res.message)
          }
          this.pdfLoadGudie = false
        }
      }).catch(error =>{
        this.$message.error('服务器连接失败!')
        this.pdfLoadGudie = false
      })
    },
    // 整个页面PDF效果
    getNumPages(url) {
      let loadingTask = pdf.createLoadingTask(url)
      loadingTask.promise.then(pdf => {
        this.numPages = pdf.numPages
        this.pdfLoad = false
      }).catch((err) => {
        this.pdfLoad = false
        this.$message.error('pdf加载失败!')
      })
    },
    // 获取公司主体下拉数据
    getCompany() {
      GetCompanyentity().then(res => {
        if (res.code === 200) {
          this.companyList = res.data
        } else {
          if (!isInterceptorCode(res.code)) {
            this.$message.error(res.msg)
          }
        }
      }).catch(() => {
        this.$message.error('服务器连接失败!')
      })
    },
    // 获取签约项目+报价类型下拉数据
    getCategoryTagData(){
      GetCategoryTagList().then(res => {
        if (res.code === 200) {
          this.projectList=res.data.signedProjectList
          this.pieceTypeList= res.data.quotationTypeList
        } else {
          if (!isInterceptorCode(res.code)) {
            this.$message.error(res.msg)
          }
        }
      }).catch(() => {
        this.$message.error('服务器连接失败!')
      })
    },
    // 获取特批类型下拉数据
    getSpecialTagData(){
      let para={
        requestId:this.requestid,
        isDouble:this.searchData.isApply,
      }
      GetSpecialTagList(para).then(res => {
        if(res.code===200){
          this.isSpecialTypeList = res.data.map(obj => {
            const { isDisplay, ...rest } = obj;
            return { disabled: !isDisplay, ...rest };
          });
          if(this.searchData.specialType.length>0&&this.isSpecialTypeList.length>0){
              const selectedItems = this.searchData.specialType.map(value =>
                this.isSpecialTypeList.find(item => item.id === value).describe
              );
              this.selectedSpecialItems=selectedItems.join('\n')
          }
          this.handelSetSpecial()
        } else {
          if (!isInterceptorCode(res.code)) {
            this.$message.error(res.msg)
          }
        }
      }).catch((e) => {
        console.log(e)
        this.$message.error('服务器连接失败!')
      })
    },
    // 获取合同模板下拉数据
    getContractTemplate(templateId,specialTags) {
      let para={
        companyName: this.searchData.company,//公司主体
        signedProject:this.searchData.project,//签约项目
        quotationType:this.searchData.pieceType,//报价类型
        requestID:this.requestid,//流程id
      }
      GetConttemplate(para).then(res => {
        if (res.code === 200) {
          this.templateList = res.data.map(item => ({
            ...item,
            disabled: false
          }));
          if(this.contract_list.length>0){
            let is_save_contract_list=this.contract_list.filter(i=>i.is_created)
            if(is_save_contract_list.length>0){
              this.templateList = this.templateList.map(item => {
                // 检查是否需要禁用当前项
                const isDisabled =is_save_contract_list.some(o => o.caregoryId === item.id)
                  && item.id !== this.select_contract.caregoryId; // 当前选中的 id 不禁用
                // 更新 disabled 属性
                return { ...item, disabled: isDisabled };
              });
            }
          }
          if(templateId){
            this.searchData.template=templateId
            // 当报价类型选择的是报价协议合同时显示特批类型
            this.handelSetSpecial()
            this.searchData.specialType=specialTags.split(',')
            this.getParameter()
            this.getstandardPdf()
          }
        } else {
          if (!isInterceptorCode(res.code)) {
            this.$message.error(res.msg)
          }
        }
      }).catch((e) => {
        console.log(e)
        this.$message.error('服务器连接失败!')
      })
    },
    // 获取服务费收费标准下拉数据
    getServiceFeeData(ids){
      let para={
        signedProject:this.searchData.project,
        quotationType:this.searchData.pieceType,
      }
      GetServiceFeeList(para).then(res => {
        if(res.code===200){
          this.orderList=res.data
          if(ids){
            this.selectOrderItem=ids.split(',')
          }
        } else {
          if (!isInterceptorCode(res.code)) {
            this.$message.error(res.msg)
          }
        }
      }).catch(() => {
        this.$message.error('服务器连接失败!')
      })
    },
    // 获取最后保存成功的合同信息中的公司主体和签约项目
    getNewContractDataInfo(){
      GetNewContractData({requestId:this.requestid}).then(res => {
        if(res.code===200){
          if(res.data){
            this.searchData.company=res.data.companyName
            this.searchData.project=res.data.signProject
            let selectIndex = this.projectList.findIndex(i=>i.id===this.searchData.project)
            if(selectIndex!==-1){
              let selectOption= this.projectList.find(i=>i.id===this.searchData.project)
              if(selectOption.sfss===0){
                this.isShowApply=true
              }else{
                this.isShowApply=false
              }
            }else{
              this.isShowApply=false
            }
          }

        } else {
          if (!isInterceptorCode(res.code)) {
            this.$message.error(res.msg)
          }
        }
      }).catch((e) => {
        console.log(e)
        this.$message.error('服务器连接失败!')
      })
    },
    // 获取右侧侧制式pdf
    getstandardPdf() {
      let para = {
        requestid: this.requestid,//地址拦获取
        companyName: this.searchData.company,//下拉列表获取
        caregoryId: this.searchData.template,//下拉列表获取
      }
      if (para.companyName === '') {
        this.$message.warning('请选择公司主体')
        return
      }
      if (para.caregoryId === '') {
        this.$message.warning('请选择合同模板')
        return
      }
      this.pdfLoad = true
      GetStandard(para).then(res => {
        if (res.code === 200) {
          this.pdfUrl = res.msg
          this.getPdfPre(this.pdfUrl)
        } else {
          if (!isInterceptorCode(res.code)) {
            this.$message.error(res.msg)
          }
          this.pdfUrl=""
          this.pdfLoad = false
        }
      }).catch(() => {
        this.$message.error('服务器连接失败!')
        this.pdfLoad = false
      })
    },
    // 获取左侧参数
    getParameter() {
      let para = {
        requestid: this.requestid,//地址拦获取
        companyName: this.searchData.company,//下拉列表获取
        caregoryId: this.searchData.template,//下拉列表获取
      }
      this.pramaLoading=true
      GetTemplateParametersList(para).then(res => {
        if (res.code === 200) {
          this.dataParametersList = res.data
          this.dataParametersList.map(item=>{
            if(item.type==='select_multiple'){
              item.value=item.value.split(',')
            }
          })
          // let findPerson=this.dataParametersList.findIndex(i=>i.name==='副申请人信息')
          // if(findPerson!==-1){
          //   if(this.dataParametersList[findPerson].value){
          //     this.personTextArea=this.dataParametersList[findPerson].value
          //   }else{
          //     this.personTextArea='副申请人(配偶):\n证件号码:\n副申请人(子女)1:\n证件号码:\n副申请人(子女)2:\n证件号码:'
          //   }
          // }
          // let findSale=this.dataParametersList.findIndex(i=>i.name==='补充或变更条款')
          // if(findSale!==-1){
          //   if(!this.selectedSpecialItems){
          //     this.selectedSpecialItems=this.dataParametersList[findSale].value
          //   }
          // }
          // let findOrder=this.dataParametersList.findIndex(i=>i.name==='服务费收费标准')
          // if(findOrder!==-1){
          //   this.orderText=this.dataParametersList[findOrder].value
          //   this.getServiceFeeData()
          // }
          // 遍历 updates 动态赋值
          this.updates.forEach(({ name, value }) => {
            const item = this.dataParametersList.find(i => i.name === name);
            if (item) {
              // 根据 name 动态设置相应的变量值
              switch (name) {
                case '副申请人信息':
                  this.personTextArea = item.value || '副申请人(配偶):\n证件号码:\n副申请人(子女)1:\n证件号码:\n副申请人(子女)2:\n证件号码:';
                  break;
                case '补充或变更条款':
                  if (!this.selectedSpecialItems) {
                    this.selectedSpecialItems = item.value;
                  }
                  break;
                case '服务费收费标准':
                  this.orderText = item.value;
                  if(this.searchData.pieceType){
                    this.getServiceFeeData(); // 执行额外逻辑
                  }
                  break;
                case '联系地址':
                  this.contractInfo.address=item.value||this.contractInfo.address
                   break;
                case '联系方式':
                  this.contractInfo.contactInformation=item.value||this.contractInfo.contactInformation
                   break;
                case '账户名':
                  this.contractInfo.accountName=item.value||this.contractInfo.accountName
                   break;
                case '银行账号':
                  this.contractInfo.bankAccount=item.value||this.contractInfo.bankAccount
                   break;
                case '开户行':
                  this.contractInfo.openingBank=item.value||this.contractInfo.openingBank
                   break;
              }
            }
          });

          this.pramaLoading=false
        } else {
          this.pramaLoading=false
          if (!isInterceptorCode(res.code)) {
            this.$message.error(res.msg)
          }
          this.dataParametersList=[]
        }
      }).catch(() => {
        this.pramaLoading=false
        this.dataParametersList=[]
        this.$message.error('服务器连接失败!')
      })
    },
    // 身份证号正则
    validateIDCard(idCard) {
      // 正则表达式
      const regExp = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;

      // 判断是否匹配正则表达式
      if (regExp.test(idCard) === false) {
        return false;
      }

      // 验证最后一位校验码
      if (idCard.length === 18) {
        const weights = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; // 加权因子
        const checkCodes = '10X98765432'; // 校验码对应值
        let sum = 0;
        for (let i = 0; i < 17; i++) {
          sum += parseInt(idCard[i]) * weights[i];
        }
        const mod = sum % 11;
        const checkCode = checkCodes.charAt(mod);
        if (idCard.charAt(17).toUpperCase() !== checkCode) {
          return false;
        }
      }
      return true;
    },
    // 护照正则
    valPassport(idCard){
      const regExp = /^[A-Z]{1}[0-9]{8,9}$/;
      // 判断是否匹配正则表达式
      if (regExp.test(idCard) === false) {
        return false;
      }
    },
    // 转换金额
    digitUppercase(num,guide) {
      if (!num || num === '.') {
        if(guide){
          this.money_big_guide = '';
          return;
        }else{
          this.money_big = '';
          return;
        }

      }
      if (Math.floor(Math.abs(num)).toString().length > 12) {
        if(guide){
          this.money_big_guide = '金额过大,应小于1000亿元!';
        }else{
          this.money_big = '金额过大,应小于1000亿元!';
        }
        this.disableBig = true;
        return;
      } else {
        this.disableBig = false;
      }

      let n = num;
      const fraction = ['角', '分'];
      const digit = [
        '零', '壹', '贰', '叁', '肆',
        '伍', '陆', '柒', '捌', '玖',
      ];
      const unit = [['元', '万', '亿'], ['', '拾', '佰', '仟']];
      const head = n < 0 ? '欠' : '';
      n = Math.abs(n);

      let s = '';

      // 处理小数部分
      let decimalPart = Math.round(n * 100); // 小数部分乘以100，取整
      for (let i = 0; i < fraction.length; i++) {
        const digitIndex = Math.floor(decimalPart / Math.pow(10, (1 - i))) % 10; // 获取角和分
        s += (digit[digitIndex] + fraction[i]).replace(/零./, ''); // 替换零
        decimalPart = decimalPart % Math.pow(10, (1 - i)); // 去除处理过的部分
      }

      // 如果没有小数部分，设为整
      s = s || '整';
      n = Math.floor(n); // 处理整数部分

      // 处理整数部分
      for (let i = 0; i < unit[0].length && n > 0; i++) {
        let p = '';
        for (let j = 0; j < unit[1].length && n > 0; j++) {
          p = digit[n % 10] + unit[1][j] + p;
          n = Math.floor(n / 10);
        }
        s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
      }
      if(guide){
        this.money_big_guide = head + s.replace(/(零.)*零元/, '元').replace(/(零.)+/g, '零').replace(/^整$/, '零元整');
      }else{
        this.money_big = head + s.replace(/(零.)*零元/, '元').replace(/(零.)+/g, '零').replace(/^整$/, '零元整');
      }
    },
    // 复制
    copyValue(money) {
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(money).then(() => {
            this.$message.success('复制成功');
          }, () => {
            this.$message.error('复制失败');
          }
        )
      } else {
        // 创建text area
        const textArea = document.createElement('input')
        textArea.value = money
        // 使text area不在viewport，同时设置不可见
        document.getElementById("tool").appendChild(textArea)
        textArea.focus()
        textArea.select()
        return new Promise((resolve, reject) => {
          // 执行复制命令并移除文本框
          document.execCommand('copy') ? resolve() : reject(new Error('出错了'))
          textArea.remove()
        }).then(() => {
            this.$message.success('复制成功');
          }, () => {
            this.$message.error('复制失败');
          }
        )
      }
    },
    // 查看当前合同数据
    getContractPreviewList(contractId) {
      let para={
        requestId: this.requestid,//流程id
        contractId:contractId
      }
      GetContractPreviewData(para).then(res => {
        if (res.code === 200) {
          this.searchData.company = res.data.companyName
          this.searchData.project=res.data.signedProject
          let selectIndex = this.projectList.findIndex(i=>i.id===this.searchData.project)
          if(selectIndex!==-1){
            let selectOption= this.projectList.find(i=>i.id===this.searchData.project)
            if(selectOption.sfss===0){
              this.isShowApply=true
            }
          }
          this.searchData.pieceType=res.data.quotationType
          this.searchData.isApply=res.data.isDouble
          if (this.searchData.company&&this.searchData.project&&this.searchData.pieceType) {
            this.getContractTemplate(res.data.caregoryId,res.data.specialTags)
            if(res.data.multipleId&&this.searchData.pieceType){
              this.getServiceFeeData(res.data.multipleId)
            }
          }
          this.searchData.date = res.data.expireTime
          this.searchData.contractName= res.data.contractName

        } else {
          if (!isInterceptorCode(res.code)) {
            this.$message.error(res.msg)
          }
        }
      }).catch((e) => {
        this.$message.error('服务器连接失败!')
      })
    },
    // 合同列表+保存并预览的刷新列表
    getContractInfoList(info){
      this.list_loading=true
      GetContractInfo({requestId:this.requestid}).then(res => {
        if (res.code === 200) {
          this.list_loading=false
          this.contract_list = res.data.map(item => ({
            ...item,
            is_created: true
          }));
          // 进入合同管理后添加新合同
          if (info === 'create'&&!this.contractId) {
            this.handleCleanData()
            this.getNewContractDataInfo()
            const newContract = {
              contractId: 1,
              contractName: "新合同",
              isSigned_already: 0,
              is_created: false,
            };
            this.un_save_contract.push(newContract);
          }
          if(this.un_save_contract.length===1){
            this.contract_list=this.contract_list.concat(this.un_save_contract)
          }

          // 如果合同有要编辑的合同就默认选中要编辑的合同,如果没有就选中进入合同管理后添加的新合同
          if(this.contract_list.length>0){

            if (this.contractId) {
              this.select_contract = this.contract_list.find(o => o.contractId === this.contractId)?this.contract_list.find(o => o.contractId === this.contractId):this.contract_list[0];
            } else {
              this.select_contract = info==='create'?this.un_save_contract[0]:this.contract_list[0];
            }
            if(this.select_contract&&this.select_contract.is_created){
              this.getContractPreviewList(this.select_contract.contractId)
            }
          }else{
            this.handleCleanData()
            this.select_contract=""
          }
        } else {
          if (!isInterceptorCode(res.code)) {
            this.$message.error(res.msg)
          }
          this.list_loading=false
        }
      }).catch(() => {
        this.$message.error('服务器连接失败!')
        this.list_loading=false
      })
    },
    // 给刷新回删除用的
    getContractList(item){
      this.list_loading=true
      GetContractInfo({requestId:this.requestid}).then(res => {
        if (res.code === 200) {
          this.list_loading=false
          this.contract_list = res.data.map(item => ({
            ...item,
            is_created: true
          }));
          if(this.un_save_contract.length===1){
            this.contract_list=this.contract_list.concat(this.un_save_contract)
          }
          if (this.contract_list.length > 0) {
            const findSelect = this.contract_list.findIndex(i => i.contractId === item.contractId);
            this.select_contract = findSelect !== -1 ? item : this.contract_list[0];
            if (this.select_contract.is_created) {
              this.getContractPreviewList(this.select_contract.contractId);
            }
          } else {
            this.searchData = {
              date: "",
              company: "",
              template: "",
              contractName: ""
            };
            this.money = "";
            this.money_big = "";
            this.dataParametersList = [];
            this.pdfUrl = "";
            this.select_contract = "";
          }
        } else {
          if (!isInterceptorCode(res.code)) {
            this.$message.error(res.msg)
          }
          this.list_loading=false
        }
      }).catch(() => {
        this.$message.error('服务器连接失败!')
        this.list_loading=false
      })
    },
    formatNumber(value) {
      // 删除非数字和小数点
      value = value.replace(/[^\d.]/g, '');

      // 限制小数点只能出现一次
      value = value.replace(/(\..*)\./g, '$1');

      // 保留小数点后两位
      value = value.replace(/^(\d*\.\d{0,2}).*$/, '$1');

      // 如果输入是以小数点开头，强制保留小数点后两位
      if (value.startsWith('.')) {
        value = value.replace(/^(\.\d{0,2}).*$/, '$1');
      }

      return value;
    },
    handleInput(value) {
      // 调用格式化函数处理输入值
      this.money = this.formatNumber(value);
      this.digitUppercase(this.money); // 执行另一个方法
    },
    handleInputGuide(value) {
      // 调用格式化函数处理输入值
      this.moneyGuide = this.formatNumber(value);
      this.digitUppercase(this.moneyGuide); // 执行另一个方法
    },
    // 富文本创建
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    // 关闭弹窗
    handelCloseDialog(){
      this.dialogTitle = ''
      this.dialogIcon= ''
      this.dialogText=''
    },
    handlePreserve(){
      if(this.dialogTitle==='删除当前合同'){
        let delIndex = this.contract_list.findIndex(i=>i.contractId===this.select_contract.contractId)
        // 如果当前的合同的未保存的未审核中的
        if(!this.select_contract.is_created){
          this.contract_list.splice(delIndex,1)
          if(this.select_contract.contractId===this.un_save_contract[0].contractId){
            this.handleCleanData()
            this.select_contract=""
          }
          this.un_save_contract=[]
          this.$message.success("删除成功")
          this.warnDialogVisable=false
          this.getContractInfoList()
        }else{
          let para={
            requestid:this.requestid,
            contractId:this.select_contract.contractId
          }
          // 掉删除接口 然后 刷新列表
          RemoveContract(para).then(res=>{
            if (res.code === 200) {
              this.$message.success("删除成功")
              this.warnDialogVisable=false
              this.handleCleanData()
              this.getNewContractDataInfo()
              this.select_contract=""
              // 刷新列表
              this.getContractInfoList()
            } else {
              if (!isInterceptorCode(res.code)) {
                this.$message.error(res.msg)
              }
            }
          }).catch(e=>{
            this.$message.error('服务器连接失败!')
          })
        }
      }else{
        this.warnDialogVisable=false
        window.close()
      }
    },
    /*引导区域*/
    highlightCurrentStep() {
      // 移除之前的高亮
      const prevElement = document.querySelector('.highlight');
      if (prevElement) {
        prevElement.classList.remove('highlight');
      }
      const currentElement = document.querySelector(`.${this.currentStep.className}`);
      if (currentElement) {
        currentElement.classList.add('highlight');
      }
    },
    nextStep() {
      if (this.currentStepIndex < this.steps.length - 1) {
        this.currentStepIndex++;
        this.highlightCurrentStep();
        if (this.currentStepIndex === 5) {
          this.getPdfPreGudie();
        }
      }
    },
    prevStep() {
      if (this.currentStepIndex > 0) {
        this.currentStepIndex--;
        this.highlightCurrentStep();
      }
    },
    finishGuide() {
      this.isGuide = false;
      this.getCompany()//获取公司主体下拉
      this.getCategoryTagData()//获取签约项目+报价类型下拉
      this.getSpecialTagData()// 获取特批类型下拉
      if (this.requestid) {
        this.getContractInfoList('create')
      }
      // 清除高亮
      const highlightedElement = document.querySelector('.highlight');
      if (highlightedElement) {
        highlightedElement.classList.remove('highlight');
      }
    },
    //赋值特批类型
    handelSetSpecial(){
      let seletIndex=this.pieceTypeList.findIndex(i=>i.id===this.searchData.pieceType)
      if(seletIndex!==-1){
        let selectOption=this.pieceTypeList.find(i=>i.id===this.searchData.pieceType)
        this.searchData.specialType=''
        this.selectedSpecialItems=''
        if(this.searchData.template&&selectOption.flmc==='分总特批价版合同'&&this.templateList.findIndex(i=>i.id===this.searchData.template)!==-1&&this.templateList.find(i=>i.id===this.searchData.template).isSpecialForeign==='0'){
          this.searchData.specialType=['0','2']
          if(this.searchData.isApply!=='0'){
            this.isSpecialTypeList.forEach(i=>i.disabled=true)
          }
        }
        else if(selectOption.flmc==='分总特批价版合同'){
          this.searchData.specialType=['0']
          if(this.searchData.isApply!=='0'){
            this.isSpecialTypeList.forEach(i=>i.disabled=true)
          }
        }else if(selectOption.flmc==='补充协议合同'){
          let arr=['1','3','4','5','6']
          if(this.searchData.isApply!=='0'){
            this.isSpecialTypeList.forEach(item => {
              if (!arr.includes(item.id)) {
                item.disabled = true;
              }
            });
          }
        }else if(this.searchData.template&&this.templateList.findIndex(i=>i.id===this.searchData.template)!==-1&&this.templateList.find(i=>i.id===this.searchData.template).isSpecialForeign==='0'){
          this.searchData.specialType=['2']
          if(this.searchData.isApply!=='0'){
            this.isSpecialTypeList.forEach(i=>i.disabled=true)
          }
        }
        if(selectOption.flmc==='补充协议合同'||
          selectOption.flmc==='分总特批价版合同'||
          (this.searchData.template&&this.templateList.findIndex(i=>i.id===this.searchData.template)!==-1&&this.templateList.find(i=>i.id===this.searchData.template).isSpecialForeign==='0')
          // ||(this.searchData.isApply==='0'&&this.templateList.find(i=>i.id===this.searchData.template).isSpecialForeign==='0')
        ){
          this.isShowSpecialType=true
        }else{
          this.isShowSpecialType=false
        }
      }else{
        this.isShowSpecialType=false
      }
    },
  },
  created() {
    if(this.$route.query.requestId){
      this.requestid =atob(this.$route.query.requestId);
    }
    if(this.$route.query.contractId){
      this.contractId=atob(this.$route.query.contractId);
    }
  },
  mounted() {
    this.loginCheckToken()
    // 监听浏览器的 beforeunload 事件
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
  // beforeDestroy() {
  //   // 在组件销毁时移除事件监听器
  //   window.removeEventListener("beforeunload", this.handleBeforeUnload);
  // },
}
</script>
<style scoped>
.step1{}
.edit-list{
    justify-content: space-between;
    display: flex;
    padding: 20px 20px 20px 0;
    width: calc(100% - 228px);
    box-sizing: border-box;
    height: 100%
}
.left-qys{
    width: calc(50% - 10px);
    position: relative;
}
.scroll-qys-left{
    overflow: auto;
    width: calc(100% + 8px);
    height: calc(100% - 110px);
    border-radius: 8px;
}
.scroll-qys-left::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
.scroll-qys-left::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 3px;
}
.right-qys{
    width: calc(50% - 10px);
    padding: 30px 24px 20px 24px;
    background: #FFFFFF;
    height: 100%;
    box-sizing: border-box;
    border-radius: 8px;
}
.main-body {
    padding: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.last-item > div:last-child {
    border-bottom: none;
}


.tab-list{
    width: 208px;
    height: 100%;
    background: #ffffff;
    padding: 20px 0 20px 0;
    box-sizing: border-box;
    position: relative;
}
.add-btn{
    display: block;
    border-color: #57C5F7;
    color: #FFFFFF !important;
    height: 50px;
    width: 166px;
    margin: 20px auto 13px; /* 上 20px，左右居中，下 13px */
    background: #2CB7F5;
}
.add-btn  span{
    margin-left: 10px;
}
.add-btn:focus, .add-btn:hover:hover{
    border-color: #57C5F7;
    color: #FFFFFF !important;
    background: #2CB7F5;
}
.close-btn{
    display: block;
    width: 166px;
    height: 50px;
    margin: 0 auto!important;
    padding: 15px 16px;
    color: #2CB7F5!important;
    background: #EFFAFF;
    border: 1px solid #B3E8FF;
}

.close-btn >>>span{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.tab-list >>> .el-button{
    border-radius: 8px;
}

.tab-list .el-button--primary.is-plain:focus, .el-button--primary.is-plain:hover{
    color: #2CB7F5!important;
    background: #ecf5ff;
    border: 1px solid #B3E8FF;
}

.tab-list-empty{
    width: 100%;
    height: calc(100% - 130px);
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.tab-contract_list{
    height: calc(100% - 130px);
    overflow-y: auto;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
}
.tab-contract_list::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
.tab-contract_list::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 3px;
}
.tab-contract_list::-webkit-scrollbar-track{
    background: #F9F9F9;
    border: 1px solid #ECEEF5;
}
.tab-item{
    width: 100%;
    height: 50px;
    display: flex;
    line-height: 50px;
    padding: 0 27px 0 21px;
    box-sizing: border-box;
    cursor: pointer;

}
.tab-item-text{
    width: 154px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
}
.tab-item-active{
    background: #F9FDFF;
    border-radius: 8px;
    border: 1px solid #94D2FF;
    color: #4E5053;
    font-weight: 600;
    padding: 0 27px 0 21px;
}

.contract-empty{
    background: #FFFFFF;
    display: flex;
    width: calc(100% - 218px);
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.label {
    font-weight: 600;
    font-size: 14px;
    color: #161B22;
    line-height: 18px;
    margin-bottom: 8px;
}
.label span{
    font-weight: 400;
    color: #FF4345;
}

.form-content >>> .el-radio__input.is-checked .el-radio__inner {
    border-color: #57C5F7;
    background: #57C5F7;
}

.form-content >>> .el-radio.is-bordered.is-checked {
    border-color: #57C5F7;
}

.form-content >>> .el-radio.is-bordered {
    padding: 8px 10px;
    height: 30px;
}
.form-content >>>.el-radio__label{
    padding-left: 6px;
}

.form-content >>> .el-radio {
    margin-left: 10px;
    margin-right: 0;
    width: 80px;
}

.form-content >>> .el-checkbox__inner::after {
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background-color: #FFF;
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) !important;
    transition: transform .15s ease-in;
}

.form-content >>> .el-checkbox__inner {
    border-radius: 50%;
}

.form-content >>> .el-checkbox__inner:hover {
    border-color: #57C5F7;
}

.form-content >>> .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background: #57C5F7;
    border-color: #57C5F7;
    border-radius: 50%;
    position: relative;
}

.form-content >>> .el-checkbox.is-bordered {
    padding: 5px 10px;
    height: 30px;
    box-sizing: border-box;
}

.form-content >>> .el-checkbox.is-checked {
    border-color: #57C5F7;
}

.form-content >>> .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #57C5F7;
}

.form-content >>> .el-checkbox {
    margin-left: 10px;
    margin-right: 0;
    width: 80px;
}

.form-content >>> .el-input__inner {
    padding: 0 30px 0 10px;
    height: 50px;
    background: #F8FBFD;
    border-radius: 8px;
    border: none;
}
.form-content >>> .el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
    background: #F8FBFD;
}
.form-content >>>.el-date-editor.el-input, .el-date-editor.el-input__inner{
    width: 100%;
}
.form-content >>>.el-date-editor .el-input__prefix{
    right: 5px;
    left: unset;
}
.form-content >>>.el-date-editor .el-input__suffix{
    right: 25px;
}


.box-code-info > div:last-child {
    border-right: 1px solid #DCDFE6;
}

.form-content >>> .el-select .el-input--suffix{
    margin-top: 2px;
}

.form-content >>>.el-textarea .el-textarea__inner{
    padding: 14px 15px 5px 15px;
    font-family: PingFangSC, PingFang SC;
    height: 100px;
    min-height: 50px!important;
    background: #F8FBFD;
    border-radius: 8px;
    border: none;
}
.form-content .person-info >>>.el-textarea__inner{
    height: 150px;
}
.form-content >>> .el-select {
    width: 100% !important;
}

.form-content >>> .el-select > .el-input {
    height: 46px;
}

.form-content >>> .el-checkbox__label{
    padding-left: 6px;
}
.radio-padding {
    background: #F8FBFD;
    line-height: 50px;
    flex-wrap: wrap;
    border-radius: 8px;
}

.loading {
    height: 45px;
    position: absolute;
    top: 50%;
    width: 200px;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 36px;
}
.loading >>> .el-loading-mask .el-loading-text {
    font-size: 28px; /* 根据需要调整大小 */
}

.emtry-info {
    font-size: 14px;
    color: #606266;
    line-height: 20px;
    height: 20px;
}

.form-content>>>.el-cascader{
    width: 100%;
}
.form-content>>>.el-cascader__search-input{
    background: #F8FBFD;
    min-width: 10px;
    margin: 2px 0 2px 10px
}
.footer-fix {
    height: 90px;
    background: #FFFFFF;
    border-radius: 8px;
    width: 100%;
    padding: 20px 20px 20px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
}
.footer-fix>>>.el-button{
    height: 50px;
    padding: 15px 15px 15px 16px;
    box-sizing: border-box;
    border: none;
    border-radius: 8px;
    display: flex;
    align-items: center;
}
.footer-fix >>>.el-button .el-icon-loading{
    margin-right: 5px;
}
.footer-fix >>>.el-button span{
    display: flex;
    align-items: center;
}
.footer-fix >>>.el-button span img{
    margin-right: 10px;
}
.footer-fix >>> .el-button--primary {
    background: #57C5F7;
    color: #FFFFFF !important;
}
.footer-fix >>> .el-button--danger {
    background: #FFF9FA;
    color: #FF4345!important;
    border: 1px solid #FBC4C4;
}
.footer-fix >>> .el-button--danger.is-plain:focus, .el-button--danger.is-plain:hover{
    background: #FFF9FA;
    border: 1px solid #FBC4C4;
    color: #FF4345!important;
}
.tool {
    background-color: #FFF;
    padding: 30px 24px 20px 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    height: 152px;
    box-sizing: border-box;
}

.tool .tool-title {
    font-weight: 600;
    font-size: 18px;
    color: #333333;
    line-height: 28px;
    height: 28px;
    margin-bottom: 10px;
}

.tool .tool-flex {
    display: flex;
    padding-left: 4px;
}

.tool .tool-left {
    width: 200px;
    margin-right: 10px;
}

.tool .tool-right {
    flex-grow: 1;
    width: calc(100% - 230px);
    display: flex;
}
.tool-flex >>> .el-input__inner{
    padding: 0 30px 0 10px;
    height: 50px;
    background: #F8FBFD;
    border-radius: 8px;
    border: none;
}
.tool-right >>> .el-button--primary{
    flex-shrink: 0;
    background: #2CB7F5;
    border:none;
    color: #FFFFFF !important;
    padding: 15px 16px;
    height: 50px;
    box-sizing: border-box;
    border-radius: 8px;
    margin-left: 10px;
    width: 82px;
}
.tool-right >>> .el-button span{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.form-title{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}
.left-border{
    width: 3px;
    height: 12px;
    background: #2CB7F5;
    margin-right: 6px;
}
.form-text-info{
    height: 22px;
    font-weight: 600;
    font-size: 16px;
    color: #161B22;
    line-height: 22px;
}
.form-label{
    line-height: 20px;
    margin-bottom: 8px;
    color: #161B22;
    font-weight: 600;
}
.form-label span{
    font-weight: 400;
    font-size: 14px;
    color: #FF4345;
}
.re-multiple{
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
    color: #999999;
}

.moduleForm-box{
    /*height: 278px;*/
    padding: 30px 24px 20px 24px;
    box-sizing: border-box;
    margin-bottom: 20px;
    background: #FFFFFF;
    border-radius: 8px;
}
.moduleForm{
    padding: 0 0 0 4px;
}
.moduleForm >>>.el-form-item{
    margin-right: 40px;
    width: calc(50% - 20px);
}
.moduleForm >>>.el-form-item:nth-child(2n){
    margin-right: 0;
}

.moduleForm >>> .el-select,.moduleForm >>> .el-input__inner,.moduleForm >>>.el-form-item__content,.moduleForm >>>.el-date-editor.el-input, .el-date-editor.el-input__inner{
    width: 100%;
}

.moduleForm >>>.el-date-editor .el-input__suffix{
    right: 30px;
}
.moduleForm >>>.el-input__prefix{
    right: 5px;
    left: unset;
}
.moduleForm .first-item{
    margin-bottom: 20px;
}
.moduleForm >>>.el-select .el-input__inner{
    padding: 0 30px 0 10px;
}

.moduleForm >>>.el-input__inner{
    padding: 0 30px 0 10px;
    height: 50px;
    background: #F8FBFD;
    border-radius: 8px;
    border: none;
}
.moduleForm >>>.el-select .el-input__inner:focus,
.moduleForm >>>.el-input__inner:focus{
    border-color: #409EFF
}
.moduleForm >>>.el-cascader{
    width: 100%;
}
.moduleForm >>>.el-cascader__search-input{
    margin: 2px 0 2px 10px;
    background: #F8FBFD;
}
.remain-special{
    padding: 30px 24px 0 24px;
    box-sizing: border-box;
    margin-bottom: 20px;
    background: #FFFFFF;
    border-radius: 8px;
}
.special-box{
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap;
}
.special-item{
    display: flex;
    align-items: center;
    width: 140px;
    justify-content: space-between;
    margin-right: 108px;
    margin-bottom: 30px;
}
.special-title{
    /*height: 20px;*/
    font-weight: 600;
    font-size: 14px;
    color: #161B22;
    line-height: 20px;
}
.special-times{
    flex-shrink: 0;
    width: 29px;
    height: 24px;
    background: #F3FBFF;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    color: #2CB7F5;
    line-height: 24px;
    text-align: center;
}
.left-content{
    padding: 30px 20px 0 20px;
    border-radius: 8px;
}
.param-box{
    height: 268px;
    display: flex;
    align-items: center;
}
.contact-form{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}
.contact-form .public-textarea-item{
    width: 100%;
    margin-bottom: 20px;
}

.contact-form .public-item{
    width: calc(50% - 20px);
    margin-bottom: 20px;
}

.tool-right>>>.el-input.is-disabled .el-input__inner {
    color: #606266;
    cursor: not-allowed;
}
.tool-right>>>.el-button--primary.is-disabled{
    background-color: #D5D5D5;
}
.contract-emtry{
    height: 216px!important;
}
/*提示弹窗*/
.warn-dialog{
    display: flex;
    align-items: center;
    justify-content: center;
}
.warn-dialog>>>.el-dialog{
    background: #FFFFFF;
    border-radius: 8px;
    margin: 0!important;
}
.warn-dialog>>>.el-dialog__body{
    display: flex;
    justify-content: center;
    padding: 20px 33px;
    align-items: flex-start;
}

.warn-dialog>>>.el-dialog__header{
    padding: 30px 0 0 0;
    height: 25px;
    font-weight: 600;
    font-size: 18px;
    color: #161B22;
    line-height: 25px;
    text-align: center;
}

.warn-dialog>>>.el-dialog__footer{
  padding: 10px 30px 30px 0;
}
.warn-dialog>>>.el-button--primary{
    color: #FFFFFF!important;
    background: #2CB7F5;
    border-radius: 5px;
    border:none
}
.wang-editor>>>.w-e-text-container{
    padding: 95px 89px 0 88px;
}
.wang-editor>>>.w-e-text-container   p{
    margin: 0;
}
/* 隐藏工具栏 */
.wang-editor>>>.w-e-toolbar{
    display: none;
}
.warn-dialog>>>.el-button--primary:focus, .warn-dialog>>>.el-button--primary:hover{
    color: #FFFFFF!important;
    background: #2CB7F5;
    border-radius: 5px;
}
.dialog-img{
    width: 14px;
    height: 14px;
    margin-right: 10px;
    margin-top: 2px;
}
.dialog-img img{
    width: 14px;
    height: 100%;
}
.result-info{
    font-weight: 400;
    font-size: 14px;
    color: #161B22;
    line-height: 20px;
}
/*提示弹窗*/

/*引导区域*/

/* 高亮边框 */

/* 遮罩层样式 */
.guide-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1000;
}

/* 指导框样式 */
.guide-box {
    position: absolute;
    background: white;
    color: black;
    max-width: 755px;
    box-sizing: border-box;
    padding: 30px;
    border-radius: 8px;
    z-index: 1001;
    top: 80px;
    left: 231px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.guide-title{
    font-weight: 600;
    font-size: 24px;
    color: #161B22;
    line-height: 33px;
    margin-bottom: 24px;
}

.guide-footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
}
/* 按钮样式 */
.guide-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.guide-buttons>>>.el-button--primary,.guide-buttons >>>.el-button--primary:focus, .guide-buttons >>>.el-button--primary:hover{
    background: #2CB7F5;
    border: none;
    padding: 0 30px;
    color: #FFFFFF!important;
    font-size: 18px;
    height: 50px;
    line-height: 50px;
}
.guide-buttons>>>.el-button--primary.is-plain,guide-buttons >>>.el-button--primary.is-plain:focus, .guide-buttons >>>.el-button--primary.is-plain:hover{
    padding: 0 30px;
    background: #F3F5F7;
    border-radius: 8px;
    color: #2CB7F5!important;
    font-size: 18px;
    height: 50px;
    line-height: 50px;
}
.guide-buttons>>>.el-button+.el-button, .el-checkbox.is-bordered+.el-checkbox.is-bordered{
    margin-left: 20px;
}
.block{
    font-size: 18px;
    color: #909399;
    line-height: 25px;
    height: 25px;
}
.step1,.step2,.step3,.step4,.step6{
    position: relative;
}
.mark{
    width: 8px;
    height: 8px;
    background: #2CB7F5;
    border-radius: 4px;
    margin-right: 9px;
    margin-top: 5px;
    flex-shrink: 0;
}
.guide-item{
    display: flex;
    align-items: flex-start;
    font-weight: 400;
    font-size: 18px;
    color: #161B22;
    line-height: 25px;
    margin-bottom: 16px;
}
.guide-item:last-child{
    margin-bottom: 0;
}
.arrow{
    width: 20px;
    height: 20px;
    background: #FFFFFF;
    transform: rotate(45deg); /* 顺时针旋转 45 度 */
    position: absolute;
    left: -5px;
    top: 30px;
}
/*引导区域*/
.question-icon{
    display: inline-block;
    vertical-align: middle;
    cursor: pointer
}
.question-icon img{
    margin-top: 3px;
}
</style>
