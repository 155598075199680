import Vue from 'vue'
import Router from 'vue-router'
import Home from '../components/Home.vue'
import Login from '../components/login.vue'
import ErrorPage from '../components/errorPage.vue'
import ContractStandard from '../components/contractStandard.vue'
Vue.use(Router)


const routes = [
  // 免登
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  // 错误页
  {
    path: '/errorPage',
    name: 'errorPage',
    component: ErrorPage,
  },
  // 制式合同
  {
    path: '/contractStandard',
    name: 'contractStandard',
    component: ContractStandard
  },
  // 非制式合同
  {
    path: '/contractLock',
    name: 'contractLock',
    component: () => import(/* webpackChunkName: "about" */ '../components/contractLock.vue')
  },
  // 合同预览
  {
    path: '/contractPreview',
    name: 'contractPreview',
    component: () => import(/* webpackChunkName: "about" */ '../components/contractPreview.vue')
  },
  // // 新手引导
  // {
  //   path: '/beginnerGuide',
  //   name: 'beginnerGuide',
  //   component: () => import(/* webpackChunkName: "about" */ '../components/beginnerGuide.vue')
  // },
]

const router = new Router({
  routes: routes
})

export default router
