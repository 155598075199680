import request from "../service/request"
function dynamicUrl(url) {
  if (url) {
    let exportUrl = '';
    Object.keys(url).map((key) => {
      exportUrl += key + '=' + url[key] + '&';
    })
    exportUrl = exportUrl.substring(exportUrl.length - 1, 0)
    return exportUrl;
  }
}
// 获取公司主体下拉
export const GetCompanyentity= ()=> request.get('/contractLock/companyList')
// 获取制式下拉选中的(查单个的)
export const GetContractPreviewData= (para)=> request.get('/contractLock/getContractPreviewData?'+dynamicUrl(para))
// 获取制式合同pdf
export const GetStandard= (para)=> request.post('/contractLock/templatePage',para)
// 获取制式合同参数
export const GetTemplateParametersList= (para)=> request.post('/contractLock/templateParametersList',para)

// 获取合同模板下拉
export const GetConttemplate= (para)=> request.post('/contractLock/categoryList',para)

// 保存制式合同
export const CreateStandardContract= (para)=> request.post('/contractLock/createStandardContract',para)

// 制式合同列表
export const GetContractInfo= (para)=> request.get('/contractLock/getContractInfoList?'+dynamicUrl(para))
// 删除合同
export const RemoveContract= (para)=> request.post('/contractLock/removeContract',para)
// 制式合同检查身份
export const CheckPerson= (para)=> request.get('/contractLock/qxAuth2check?'+dynamicUrl(para))
// 签约项目接口+报价类型下拉数据
export const GetCategoryTagList= ()=> request.get('/contractLock/categoryTagList')
// 特批类型下拉数据
export const GetSpecialTagList= (para)=> request.get('/contractLock/getSpecialTagList?'+dynamicUrl(para))
// 服务费收费标准下拉数据
export const GetServiceFeeList= (para)=> request.get('/contractLock/getServiceFeeList?'+dynamicUrl(para))
// 获取最后保存成功的合同信息中的公司名称和签约项目
export const GetNewContractData= (para)=> request.get('/contractLock/getNewContractData?'+dynamicUrl(para))
/* E 制式 2024-05-14*/


/* S 非制式 2024-05-15*/
// 获取非制式下拉选中的+签署位置 /contractLock/getNonContractPreviewData
export const GetNonContractPreviewData= (para)=> request.get('/contractLock/getNonContractPreviewData?'+dynamicUrl(para))
//关键字索引下拉
export const GetKeyWordIndexList= ()=> request.get('/contractLock/getKeyWordIndexList')
// 签署类型下拉
export const GetSignatureTypeList= ()=> request.get('/contractLock/getSignatureTypeList')
// 保存非制式合同
export const CreateNonStandardContract= (para)=> request.post('/contractLock/createNonStandardContract',para)
/* E 非制式 2024-05-15*/