<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {

  data() {
    return {

    }
  },
  watch: {

  },
  methods: {

  },
  created() {

  },
  mounted() {

  },
}
</script>
<style scoped>

</style>
