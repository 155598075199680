<template>
  <div>

  </div>
</template>
<script>
import {CheckPerson} from "@/api";
import {isInterceptorCode} from "@/common/commonFuncs";

export default {
  components: {},
  data() {
    return {

    }
  },
  created() {
    this.loginCheckToken()
  },
  computed: {

  },
  methods: {
    loginCheckToken(){
      let requestId='';
      let contractId='';
      if(this.$route.query.requestId){
        requestId= atob(this.$route.query.requestId);
      }
      if(this.$route.query.contractId){
        contractId=atob(this.$route.query.contractId);
      }
      let para={
        requestId:requestId,
        contractId:contractId,
      }
      CheckPerson(para).then(res => {
        if (res.code === 200) {
          sessionStorage.setItem('is_login','1')
          // 去制式合同-合同列表
          if(this.$route.query.type==='0'){
            this.$router.push({
              name: 'contractStandard',
              query:para
            })
          }else if(this.$route.query.type==='1'){
            // 去制式合同-预览
            this.$router.push({
              name: 'contractPreview',
              query:para
            })
          }
        } else {
          if (!isInterceptorCode(res.code)) {
            this.$message.error(res.msg)
          }
        }
      }).catch(() => {
        this.$message.error('服务器连接失败!')
      })
    },
  }
}
</script>
<style scoped>
</style>